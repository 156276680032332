@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,700;1,300;1,700&display=swap");

@font-face {
  font-family: "pdscmic_M800";
  src: local("micr"), url(./ttf/Pdscmic_M800.ttf) format("truetype");
}
@font-face {
  font-family: "pdscmic_M800";
  src: local("micr"), url(./ttf/Pdscmic_M800.ttf) format("truetype");
}
@font-face {
  font-family: "arial";
  src: url(./ttf/ARIAL.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "arial";
  src: url(./ttf/ARIAL.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "arial";
  src: url(./ttf/ARIAL.ttf) format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "arial";
  src: url(./ttf/ARIAL.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "arial";
  src: url(./ttf/ARIAL.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
}
